import { type Fetcher } from 'swr';
import { isError, type Result } from '@replay/types/Result';
import { type ReplayError } from '@replay/types/Type_Error';
import { stringify, parseUrl } from 'query-string';
import { fetchEmacZone } from './emac/commons';
import type { Zone } from '@replay/types/Zone';

type content = Zone['content'];
type pagination = content['pagination'];
type ResultFetchZonesData = Result<content, ReplayError>;

export const pickFirstPaginatedZone = (zones: Zone[]): { index: number; pagination: pagination } | undefined => {
    const index = zones.findIndex((zone) => zone?.content?.pagination?.pages);

    if (index === -1) {
        return undefined;
    }

    return {
        index,
        pagination: zones[index].content.pagination as pagination,
    };
};

export const isValidPage = (page: number, pagination: pagination): boolean => {
    if (!pagination?.pages) return false;
    return page <= pagination.pages;
};

type FetcherProps = {
    pagination: pagination;
    page: number;
    abvGroups: string | null;
    geoblocking: string | null;
};

export const fetchPaginatedUrl: Fetcher<ResultFetchZonesData, FetcherProps> = async ({
    pagination,
    page,
    geoblocking,
    abvGroups,
}) => {
    const { url: baseUrl, query } = parseUrl(pagination?.links?.next || '');
    const newQuery = stringify({ ...query, page }, { skipNull: true });
    const newUrl = `${baseUrl}/?${newQuery}`;

    const response = await fetchEmacZone({
        url: newUrl,
        geoblocking,
        abvGroups,
    });

    if (isError<Zone['content'], ReplayError>(response)) {
        return { ...response, value: { ...response.value, base: { type: 'videoType' } } };
    }

    return {
        tag: 'Ok',
        value: response.value,
    };
};
