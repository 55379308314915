/*
 * We use a method resolveParamsFromVideosIdentifiers which from the identifiers sent on the route (1 or 2 identifiers) will return an object of type { url, type, redirect }.
 *
 * ## url
 * url will correspond to the url from which we will retrieve the data of the page to display
 *
 * ## redirect
 * redirect can be non-null only in the case of subcategories.
 * In case for a call to '/videos/category-slug/subcategory-slug',
 * the category-slug is not the real slug of the parent category of the subcategory,
 * redirect will indicate the right association 'category-slug/subcategory-slug'.
 *
 * ## type
 * type will inform about the type of page to display, and therefore about the components to use.
 *
 * In case we can not identify the type of page requested,
 * the type is null (and the url!)
 */
import { useRouter } from 'next/router';
import useSWR, { type Fetcher, type SWRResponse } from 'swr';
import { v4 } from 'uuid';
import { useTranslation } from 'react-i18next';
import { type Translate } from '@replay/i18n/types/translations';
import { type ReplayError } from '@replay/types/Type_Error';
import { fetchPaginatedUrl, isValidPage, pickFirstPaginatedZone } from '../../shared/fetchPaginatedData';
import { type Locale } from '@replay/i18n/types/locale';
import { type Page, type PageType } from '@replay/types/Page';
import { isError, isOk, type Result } from '@replay/types/Result';
import { addEbuBoxZoneToPage } from './ebuBox';
import { resolveParamsFromVideosIdentifiers } from './resolveParamsFromVideosIdentifiers';
import { fetchEmacPage } from '../../shared/emac/commons';
import { stringify } from 'query-string';
import { ensurePreviewDate } from '../../shared/fetchPageData';
import type { Zone } from '@replay/types/Zone';

export type Base = { type: PageType; redirect: string | null };
export type Query = {
    identifiers: string[];
    page: string | null;
    trailer: boolean | null;
    t?: string;
};

export type ResultVideoFetch = Result<Page, ReplayError>;
type ParamType = Record<string, string | number | undefined>;

export type FetcherProps = {
    locale: Locale;
    page?: number;
    geoblocking: string | null;
    abvGroups: string;
    translate: Translate;

    params: ParamType | null;
    type: PageType;
    redirect: string | null;
    url: string | null;
};

export const fetchVideoData: Fetcher<ResultVideoFetch, FetcherProps> = async ({
    page,
    geoblocking,
    abvGroups,
    translate,
    type,
    url,
    params,
}) => {
    if (!url) {
        return {
            tag: 'Error',
            value: {
                type: 'Fetch',
                err: {
                    type: 'Http',
                    url: '',
                    stack: null,
                    'x-correlation-id': v4(),
                    statusCode: 404,
                    codeDefinition: 'NotFound',
                },
            },
        };
    }

    const stringifiedParams = params ? stringify(params, { skipNull: true }) : '';
    const response = await fetchEmacPage({
        url: `${url}${stringifiedParams ? `?${stringifiedParams}` : ''}`,
        geoblocking,
        abvGroups,
    });

    if (isError<Page, ReplayError>(response)) {
        return response;
    }

    const emacPage = addEbuBoxZoneToPage(type, translate, response.value);

    const paginatedZone = pickFirstPaginatedZone(emacPage.zones);
    if (page && paginatedZone && isValidPage(page, paginatedZone?.pagination)) {
        const { index: paginatedZoneIndex, pagination } = paginatedZone;
        const transformDataValue = (zoneContent: Zone['content']): Page => ({
            ...emacPage,
            zones: emacPage.zones.map((zone, index) => {
                if (index === paginatedZoneIndex) {
                    return {
                        ...zone,
                        content: {
                            ...zoneContent,
                        },
                    };
                }
                return zone;
            }),
        });
        const newData = await fetchPaginatedUrl({
            abvGroups,
            geoblocking,
            pagination,
            page,
        });

        if (isOk<Zone['content'], ReplayError>(newData))
            return {
                tag: 'Ok',
                value: transformDataValue(newData.value),
            };
    }

    return {
        tag: 'Ok',
        value: {
            ...emacPage,
        },
    };
};

const ensureQueryIdentifiers = (identifiers: string | string[] | undefined): string[] => {
    if (!identifiers) {
        return [];
    }
    return Array.isArray(identifiers) ? identifiers : [identifiers];
};

const ensureQueryPage = (page: string | string[] | undefined): number | undefined => {
    if (!page || Array.isArray(page)) {
        return;
    }
    const pageAsNumber = parseInt(page, 10);
    if (isNaN(pageAsNumber)) {
        return;
    }

    return pageAsNumber;
};

export const useVideoData = ({
    geoblocking,
    initialData,
    locale,
    abvGroups,
}: {
    geoblocking: string | null;
    initialData: ResultVideoFetch;
    locale: Locale;
    abvGroups: string;
}): SWRResponse<ResultVideoFetch, ReplayError> => {
    const { query } = useRouter();
    const { t: translate } = useTranslation();
    const previewDate = ensurePreviewDate(query.previewDate);

    const { type, url, redirect } = resolveParamsFromVideosIdentifiers(
        locale,
        ensureQueryIdentifiers(query.identifiers),
        !!query.trailer,
    );
    return useSWR<ResultVideoFetch, ReplayError, FetcherProps>(
        {
            locale,
            page: ensureQueryPage(query.page),
            geoblocking,
            abvGroups,
            translate,
            params: { previewDate },
            type,
            url,
            redirect,
        },
        fetchVideoData,
        {
            fallbackData: initialData,
            revalidateOnFocus: false,
        },
    );
};
