import Head from 'next/head';
import queryString from 'query-string';
import { type ReactElement } from 'react';
import { notEmpty } from '../../shared/notEmpty';
import { DeepLink } from '../../shared/metas/DeepLink';
import { JsonLdBreadcrumbList } from '../../shared/metas/JsonLd';
import { getCategoryFromParent, ParentTree } from '../../shared/metas/parent';
import { Metas, type MetaWithPageProps } from '../../shared/metas/Metas';
import { getNextPage, type Zone } from '@replay/types/Zone';
import { pickFirstPaginatedZone } from '../../shared/fetchPaginatedData';

type PaginationUrl = { type: 'prev' | 'next'; value: string };

export const getPaginationUrls = (url: string, zones: Zone[]): PaginationUrl[] => {
    const { url: baseUrl, query } = queryString.parseUrl(url);

    const paginatedZone = pickFirstPaginatedZone(zones);

    if (!paginatedZone) {
        return [];
    }

    const { pagination } = paginatedZone;

    const currentPage = pagination?.page || 1;
    const nextPage = getNextPage(pagination);

    if (!nextPage && currentPage === 1) {
        return [];
    }

    const prevQuery = queryString.stringify({ ...query, page: currentPage - 1 }, { skipNull: true });
    const prevUrl = `${baseUrl}?${prevQuery}`;

    if (!nextPage) {
        return [{ type: 'prev', value: prevUrl }];
    }

    const nextQuery = queryString.stringify({ ...query, page: currentPage + 1 }, { skipNull: true });
    const nextUrl = `${baseUrl}?${nextQuery}`;

    if (currentPage === 1) {
        return [{ type: 'next', value: nextUrl }];
    }

    return [
        { type: 'next', value: nextUrl },
        { type: 'prev', value: prevUrl },
    ];
};

type Props = MetaWithPageProps & { zones: Zone[]; parent: ParentTree; mamiBaseUrl: string };

export const CollectionMetas = ({
    title,
    description,
    url,
    language,
    alternativeLanguages,
    page,
    zones,
    parent,
    meta,
}: Props): ReactElement => {
    const category = getCategoryFromParent(parent);

    return (
        <>
            <Metas
                title={title}
                description={description}
                url={url}
                language={language}
                alternativeLanguages={alternativeLanguages}
                meta={meta}
            />
            <DeepLink page={page} url={url} zones={zones} type="collection" />
            <JsonLdBreadcrumbList
                key="jsonld-website"
                items={[category && { title: category.label, url: category.url }, { title, url }].filter(notEmpty)}
            />
            <Head>
                {getPaginationUrls(url, zones).map(({ type, value }) => (
                    <link key={type} rel={type as string} href={value} />
                ))}
                <meta name="robots" content="max-image-preview:large" />
            </Head>
        </>
    );
};
