import { GenrePage } from '@artegeie/design-system/replay';
import { type ReactElement } from 'react';

import { getAlternativeLanguages, getMeta, getPage, type Page } from '@replay/types/Page';
import { type Domain } from '@replay/types/MyGetServerSidePropsResult';
import { useTranslation } from 'react-i18next';
import { JsonLdBreadcrumbList } from '../../shared/metas/JsonLd';
import { DeepLink } from '../../shared/metas/DeepLink';
import { Metas } from '../../shared/metas/Metas';

type Props = {
    data: Page;
    domain: Domain;
};

const categoryPageCodes = ['ACT', 'CIN', 'CPO', 'DEC', 'DOR', 'EMI', 'HIS', 'SCI', 'SER'] as const;
type CategoryPageType = (typeof categoryPageCodes)[number];
export const isCategoryPageType = (page: string): page is CategoryPageType =>
    categoryPageCodes.includes(page as CategoryPageType);

const CategoryPage = ({ data, domain }: Props): ReactElement => {
    const alternativeLanguages = getAlternativeLanguages(data);
    const meta = getMeta(data);
    const page = getPage(data);
    const { t: translate } = useTranslation();
    return (
        <>
            <Metas
                title={meta.title}
                description={
                    isCategoryPageType(page)
                        ? translate(`replay_internal.seo.description.category.${page}`, {
                              description: meta.description,
                          })
                        : ''
                }
                url={data.url}
                language={data.language}
                alternativeLanguages={alternativeLanguages}
                meta={meta}
            />
            <DeepLink page={page} url={data.url} type="emac" />
            <JsonLdBreadcrumbList key="jsonld-breadcrumb-list" items={[{ title: meta.title, url: data.url }]} />
            {data && <GenrePage page={data} domain={domain} />}
        </>
    );
};

export default CategoryPage;
