import { env } from '@replay/env-generator';
import { Translate, TranslateLabels } from '@replay/i18n/types/translations';
import { type Page, PAGE_TYPE_ACT_WITH_EBU_BOX, type PageType } from '@replay/types/Page';
import { type Zone } from '@replay/types/Zone';

export const needEbuBox = (type: PageType): boolean => {
    return type == PAGE_TYPE_ACT_WITH_EBU_BOX;
};

export const getEbuBoxScript = (type: PageType): string | undefined => {
    return needEbuBox(type) ? env.NEXT_PUBLIC_EBU_BOX_URL : undefined;
};

type EbuBoxKey = {
    id: TranslateLabels;
    title: TranslateLabels;
    subtitle: TranslateLabels;
};
const key: EbuBoxKey = {
    id: 'replay_internal.ebuBox.id',
    title: 'replay_internal.ebuBox.title',
    subtitle: 'replay_internal.ebuBox.subtitle',
};

const constructEbuZone = (translate: Translate): Zone => {
    return {
        code: 'ebu-box',
        content: { data: [], pagination: null },
        id: translate(key.id),
        link: null,
        slug: null,
        title: translate(key.title),
        description: translate(key.subtitle),
        displayOptions: {
            theme: null,
            template: 'ebu-box',
            showZoneTitle: false,
            showItemTitle: false,
        },
        authenticatedContent: null,
        groupedZonesName: null,
        displayTeaserGenre: false,
    };
};

export const addEbuBoxZoneToPage = (type: PageType, translate: Translate, page: Page): Page => {
    if (env.NEXT_PUBLIC_FEATURE_FLAGS_EBU_BOX && needEbuBox(type)) {
        return {
            ...page,
            zones: [...page.zones, constructEbuZone(translate)],
        };
    }
    return page;
};
