import { ListPage as ListPageDs } from '@artegeie/design-system/replay';
import {
    allVideoTypes,
    AUDIO_DESCRIPTION,
    getReverseVideoType,
    type VideoType,
} from '@artegeie/page-constants/dist/videoTypes';
import { type ReactElement } from 'react';

import { type Translate } from '@replay/i18n/types/translations';
import { notEmpty } from '../../shared/notEmpty';
import { type Locale } from '@replay/i18n/types/locale';
import { getAlternativeLanguages, getMeta, getPage, type Page } from '@replay/types/Page';
import { type Domain } from '@replay/types/MyGetServerSidePropsResult';
import { Metas } from '../../shared/metas/Metas';
import { DeepLink } from '../../shared/metas/DeepLink';
import { JsonLdBreadcrumbList } from '../../shared/metas/JsonLd';

type VideoLink = {
    id: string;
    type: VideoType;
    label: string;
    value: string;
};

export const getTypedVideosLinks = (locale: Locale, translate: Translate): VideoLink[] =>
    allVideoTypes
        .filter((vt) => vt !== AUDIO_DESCRIPTION)
        .map((videoType): VideoLink | undefined => {
            const localizedVideoType = getReverseVideoType(locale, videoType);
            return localizedVideoType
                ? {
                      id: localizedVideoType,
                      type: videoType,
                      label: translate(`replay_internal.formaters.videos.sort_by.${videoType}`),
                      value: `/${locale}/videos/${localizedVideoType}/`,
                  }
                : undefined;
        })
        .filter(notEmpty);

type Props = {
    data: Page;
    locale: Locale;
    translate: Translate;
    domain: Domain;
};

const ListPage = ({ data, locale, translate, domain }: Props): ReactElement => {
    const page = getPage(data);
    const isAudioDescriptionPage = page === AUDIO_DESCRIPTION;
    const links = isAudioDescriptionPage ? [] : getTypedVideosLinks(locale, translate);
    const currentLink = links.find((l) => l.type === page);
    // @TODO: default to null once ListPageDS.dropdownValue is nullable
    const currentLinkValue = currentLink ? currentLink.value : '';
    const alternativeLanguages = getAlternativeLanguages(data);
    const meta = getMeta(data);

    return (
        <>
            <Metas
                title={meta.title}
                description={meta.description}
                url={data.url}
                language={data.language}
                alternativeLanguages={alternativeLanguages}
                meta={meta}
            />
            <DeepLink page={page} url={data.url} type="emac" />
            <JsonLdBreadcrumbList key="jsonld-breadcrumb-list" items={[{ title: meta.title, url: data.url }]} />

            <ListPageDs page={data} dropdownItems={links} dropdownValue={currentLinkValue} domain={domain} />
        </>
    );
};

export default ListPage;
