import { type ReactElement } from 'react';
import Head from 'next/head';
import { isAfter } from '@artegeie/date';

import { Metas, type MetaWithPageProps } from '../../shared/metas/Metas';
import { DeepLink, DeepLinkType } from '../../shared/metas/DeepLink';
import { JsonLdBreadcrumbList, JsonLdProgram } from '../../shared/metas/JsonLd';
import { groupParentsByType, type ParentTree } from '../../shared/metas/parent';
import { Spotlight } from '../../shared/metas/Spotlight';
import { type Zone } from '@replay/types/Zone';
import { type Teaser } from '@replay/types/Teaser';
import { notEmpty } from '../../shared/notEmpty';
import { getProgramFromZones } from './getProgramFromZones';
import { type Locale } from '@artegeie/page-constants/dist/i18n';

export const isProgramGone = (program: Teaser | undefined): boolean => {
    return program ? isAfter(new Date(), program.publishEnd) : true;
};

const getEmbedUrl = (language: Locale, program: Teaser | undefined, isProgramGone: boolean): string | undefined => {
    if (program && !isProgramGone) {
        return `https://www.arte.tv/embeds/${language}/${program.programId}`;
    }
    return undefined;
};

type Props = MetaWithPageProps & {
    seo?: {
        title: string;
    };
    mamiBaseUrl: string;
    zones: Zone[];
    parent?: ParentTree;
    type?: DeepLinkType;
};

export const ProgramMetas = ({
    title,
    description,
    url,
    language,
    alternativeLanguages,
    page,
    seo,
    mamiBaseUrl,
    zones,
    parent,
    meta,
    type = 'program',
}: Props): ReactElement => {
    const { category, subcategory } = groupParentsByType(parent);
    const program = getProgramFromZones(zones);
    const noIndex = isProgramGone(program);
    const embedUrl = getEmbedUrl(language, program, noIndex);

    return (
        <>
            <Metas
                title={seo ? seo.title : title}
                description={description}
                url={url}
                language={language}
                alternativeLanguages={alternativeLanguages}
                meta={meta}
            />
            <Head>
                <meta name="robots" content={noIndex ? 'noIndex' : 'max-image-preview:large'} />
                <meta key="og:type" property="og:type" content="video.other" />
                {embedUrl && <meta key="og:video:url" property="og:video:url" content={embedUrl} />}
                {embedUrl && <meta key="og:video:secure_url" property="og:video:secure_url" content={embedUrl} />}
                <meta key="og:video:width" property="og:video:width" content="1280" />
                <meta key="og:video:height" property="og:video:height" content="720" />
                {embedUrl && <meta key="twitter:card" name="twitter:card" content="player" />}
                {embedUrl && <meta key="twitter:player" name="twitter:player" content={embedUrl} />}
                {embedUrl && <meta key="twitter:player:width" name="twitter:player:width" content="1280" />}
                {embedUrl && <meta key="twitter:player:height" name="twitter:player:height" content="720" />}
            </Head>
            <DeepLink page={page} url={url} type={type} />
            <Spotlight program={program} />
            <JsonLdBreadcrumbList
                key="jsonld-website"
                items={[
                    category && { title: category.label, url: category.url },
                    subcategory && { title: subcategory.label, url: subcategory.url },
                    { title, url },
                ].filter(notEmpty)}
            />
            <JsonLdProgram
                parent={parent}
                program={program}
                locale={language}
                zones={zones}
                mamiBaseUrl={mamiBaseUrl}
                url={url}
            />
        </>
    );
};
