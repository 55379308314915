/*
 * Routes prefixed with /videos can return several types of pages.
 *
 * For routes on 1 level, we can have :
 * - '/videos/listing-type' returning a video list (most viewed, most recents, ...)
 * - '/videos/category-slug' returning a category page
 * - '/videos/genre-slug' returning a genre page
 *
 * For the routes on 2 levels, we can have :
 * - '/videos/program-id/program-slug' returning a program page
 * - '/videos/collection-id/collection-slug' returning a collection page
 * - '/videos/category-slug/subcategory-slug' returning a subcategory page
 *
 * ## redirect
 * redirect can be non-null only in the case of subcategories.
 * In case for a call to '/videos/category-slug/subcategory-slug',
 * the category-slug is not the real slug of the parent category of the subcategory,
 * redirect will indicate the right association 'category-slug/subcategory-slug'.
 *
 * ## type
 * type will inform about the type of page to display, and therefore about the components to use.
 *
 * We will have 5 types (see the description of url above):
 * - categories displayed with the component Design System GenrePage
 * - subcategories displayed with the component ASG SubCollectionPage
 * - genres displayed with the component Design System GenrePage
 * - collections displayed with the component Design System RcPage
 * - programs not yet implemented
 *
 * In case we can not identify the type of page requested,
 * the type is null
 */
import { type ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { type Locale } from '@replay/i18n/types/locale';
import {
    type Page,
    type PageType,
    PAGE_TYPE_ACT_WITH_EBU_BOX,
    PAGE_TYPE_CATEGORIES,
    PAGE_TYPE_COLLECTIONS,
    PAGE_TYPE_LISTS,
    PAGE_TYPE_PROGRAMS,
    PAGE_TYPE_PROGRAMS_TRAILER,
    PAGE_TYPE_SUBCATEGORIES,
} from '@replay/types/Page';
import CategoryPage from '../category/CategoryPage';
import CollectionPage from '../collection/CollectionPage';
import GenrePage from '../genre/GenrePage';
import ListPage from '../list/ListPage';
import ProgramPage, { ProgramPageProps } from '../program/ProgramPage';
import SubcategoryPage from '../subcategory/SubcategoryPage';
import { type Domain } from '@replay/types/MyGetServerSidePropsResult';
import { type ApiPlayerConfigData, type ApiPlayerPlaylistItems } from '../../shared/api-player/commons';

type Props = {
    data: Page | null | undefined;
    type: PageType;
    locale: Locale;
    mamiBaseUrl: string;
    tcStartFrom: number | null;
    forceTrailer?: boolean;
    domain: Domain;
    apiPlayerConfig: ApiPlayerConfigData | null;
    apiPlayerPlaylist: ApiPlayerPlaylistItems | null;
};

type ProgramProps = Omit<ProgramPageProps, 'videoKind'>;

export const VideosRouter = ({
    data,
    type,
    locale,
    mamiBaseUrl,
    tcStartFrom,
    forceTrailer,
    domain,
    apiPlayerConfig,
    apiPlayerPlaylist,
}: Props): ReactElement => {
    const { t: translate } = useTranslation();

    const programProp: ProgramProps = {
        data,
        mamiBaseUrl,
        translate,
        locale,
        tcStartFrom,
        domain,
        apiPlayerConfig,
        apiPlayerPlaylist,
    };

    if (!data || type === PAGE_TYPE_PROGRAMS) {
        return <ProgramPage {...{ videoKind: 'program', ...programProp }} />;
    }

    if (type === PAGE_TYPE_PROGRAMS_TRAILER) {
        return <ProgramPage {...{ videoKind: 'trailer', ...programProp }} />;
    }

    if (type === PAGE_TYPE_COLLECTIONS) {
        return <CollectionPage data={data} mamiBaseUrl={mamiBaseUrl} forceTrailer={forceTrailer} domain={domain} />;
    }

    if (type === PAGE_TYPE_CATEGORIES || type === PAGE_TYPE_ACT_WITH_EBU_BOX) {
        return <CategoryPage data={data} domain={domain} />;
    }

    if (type === PAGE_TYPE_SUBCATEGORIES) {
        return <SubcategoryPage data={data} domain={domain} />;
    }

    if (type === PAGE_TYPE_LISTS) {
        return <ListPage data={data} translate={translate} locale={locale} domain={domain} />;
    }

    return <GenrePage data={data} domain={domain} />;
};
