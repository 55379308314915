import { SubCategoryPage } from '@artegeie/design-system/replay';
import { type ReactElement } from 'react';
import { getAlternativeLanguages, getMeta, getPage, type Page } from '@replay/types/Page';
import { type Domain } from '@replay/types/MyGetServerSidePropsResult';
import { Metas } from '../../shared/metas/Metas';
import { DeepLink } from '../../shared/metas/DeepLink';
import { JsonLdBreadcrumbList } from '../../shared/metas/JsonLd';
import { type ParentTree } from '../../shared/metas/parent';
import { notEmpty } from '../../shared/notEmpty';

type Props = {
    data: Page;
    domain: Domain;
};

export const isConcertCategory = (parent: ParentTree): boolean => (parent ? parent.page === 'ARS' : false);

const getPageComponent = (data: Page, domain: Domain) => {
    if (!data) return null;

    return <SubCategoryPage page={data} domain={domain} />;
};

const SubcategoryPage = ({ data, domain }: Props): ReactElement => {
    const pageContent = getPageComponent(data, domain);

    const alternativeLanguages = getAlternativeLanguages(data);
    const meta = getMeta(data);
    const page = getPage(data);

    return (
        <>
            <Metas
                title={meta.title}
                description={meta.description}
                url={data.url}
                language={data.language}
                alternativeLanguages={alternativeLanguages}
                meta={meta}
            />
            <DeepLink page={page} url={data.url} type="emac" />
            <JsonLdBreadcrumbList
                key="jsonld-breadcrumb-list"
                items={[
                    data.parent && {
                        title: data.parent.label,
                        url: data.parent.url,
                        logo: isConcertCategory(data.parent) ? `/img/logos/arte-concert.svg` : undefined,
                    },
                    { title: meta.title, url: data.url },
                ].filter(notEmpty)}
            />
            {pageContent}
        </>
    );
};

export default SubcategoryPage;
