import { getSubCategoryByCodeForLocale } from '@artegeie/page-constants/dist/categories';

import { getCodeAndTypeFromLocalizedSlug } from '../../shared/pageTaxinomy';
import { type Locale } from '@replay/i18n/types/locale';
import {
    type PageType,
    PAGE_TYPE_ACT_WITH_EBU_BOX,
    PAGE_TYPE_CATEGORIES,
    PAGE_TYPE_COLLECTIONS,
    PAGE_TYPE_GENRES,
    PAGE_TYPE_LISTS,
    PAGE_TYPE_PROGRAMS,
    PAGE_TYPE_PROGRAMS_TRAILER,
    PAGE_TYPE_SUBCATEGORIES,
    PAGE_TYPE_UNKNOWN,
} from '@replay/types/Page';

export const isCollectionCode = (code: string): boolean => /^RC-\d{6}$/.test(code);
export const isProgramCode = (code: string): boolean => /^\d{6}-\d{3}-[A-z]$/.test(code);
export const isGenreCode = (code: string): boolean => /^[A-Z]+(?:_[A-Z]+)*$/.test(code);
export const isCategoryCode = (code: string): boolean => /^[A-Z]{3}$/.test(code);

const getUrl = ({ code, type, locale }: { code: string; type: PageType; locale: Locale }): string | null => {
    switch (type) {
        case PAGE_TYPE_UNKNOWN:
            return null;
        case PAGE_TYPE_COLLECTIONS:
        case PAGE_TYPE_PROGRAMS:
            return `/api/emac/v4/${locale}/web/${type}/${code}/`;
        case PAGE_TYPE_PROGRAMS_TRAILER:
            return `/api/emac/v4/${locale}/web/${PAGE_TYPE_PROGRAMS}/${code}/`;
        case PAGE_TYPE_GENRES:
        case PAGE_TYPE_LISTS:
        case PAGE_TYPE_CATEGORIES:
        case PAGE_TYPE_SUBCATEGORIES:
        case PAGE_TYPE_ACT_WITH_EBU_BOX:
            return `/api/emac/v4/${locale}/web/pages/${code}/`;
    }
};

export const getParamsFromVideosIdentifiers = (
    locale: Locale,
    identifiers: string[],
    isTrailer: boolean,
): { type: PageType; code: string | null; redirect?: string } => {
    const [firstIdentifier, secondIdentifier] = identifiers;
    // routes on 1 level, could be a list, a genre or a category
    if (!secondIdentifier) {
        const taximony = getCodeAndTypeFromLocalizedSlug(locale, firstIdentifier);
        if (taximony && taximony.code) {
            const { code, type } = taximony;
            return {
                code,
                type,
            };
        }
    }

    if (isCollectionCode(firstIdentifier)) {
        return {
            code: firstIdentifier,
            type: PAGE_TYPE_COLLECTIONS,
        };
    }

    if (isProgramCode(firstIdentifier)) {
        return {
            code: firstIdentifier,
            type: isTrailer ? PAGE_TYPE_PROGRAMS_TRAILER : PAGE_TYPE_PROGRAMS,
        };
    }

    // routes on 2 level subcategory
    const { code } = getCodeAndTypeFromLocalizedSlug(locale, identifiers[1]);

    if (!code) {
        return {
            code,
            type: PAGE_TYPE_SUBCATEGORIES,
        };
    }

    const subCategoryByCode = getSubCategoryByCodeForLocale(code, locale);

    if (!subCategoryByCode) {
        return { code, type: PAGE_TYPE_SUBCATEGORIES };
    }

    const { category } = subCategoryByCode;

    return {
        code,
        type: PAGE_TYPE_SUBCATEGORIES,
        ...(category.slug !== firstIdentifier ? { redirect: `${category.slug}/${identifiers[1]}` } : {}),
    };
};

/*
 * Routes prefixed with /videos can return several types of pages.
 *
 * For routes on 1 level, we can have :
 * - '/videos/listing-type' returning a video list (most viewed, most recents, ...)
 * - '/videos/category-slug' returning a category page
 * - '/videos/genre-slug' returning a genre page
 *
 * For the routes on 2 levels, we can have :
 * - '/videos/program-id/program-slug' returning a program page
 * - '/videos/collection-id/collection-slug' returning a collection page
 * - '/videos/category-slug/subcategory-slug' returning a subcategory page
 *
 * So we see that we have two types of identifiers:
 * - slugs for ... all
 * - codes for programs and collections
 *
 * Now, and even if it would be possible to do otherwise for the categories
 * and sub-categories, we will use the codes to call EMAC. So we will have to :
 *  - use page-constant to find the codes of categories and sub-categories from their slug
 * - maintain a dictionary on Replay to find the genre codes from the slugs
 *
 * For programs and collections, the data returned by EMAC will be used
 * to test the good correspondence between the slug and the code
 *
 */
export const resolveParamsFromVideosIdentifiers = (
    locale: Locale,
    identifiers: string[],
    isTrailer: boolean,
): { type: PageType; url: string | null; redirect: string | null } => {
    const { type, code, redirect } = getParamsFromVideosIdentifiers(locale, identifiers, isTrailer);

    return {
        type,
        url: type && code ? getUrl({ code, type, locale }) : null,
        redirect: redirect || null,
    };
};
