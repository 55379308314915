import { type Locale } from '@replay/i18n/types/locale';
import {
    PageType,
    PAGE_TYPE_LISTS,
    PAGE_TYPE_UNKNOWN,
    PAGE_TYPE_ACT_WITH_EBU_BOX,
    PAGE_TYPE_CATEGORIES,
    PAGE_TYPE_SUBCATEGORIES,
    PAGE_TYPE_GENRES,
} from '@replay/types/Page';
import { Codes, type Slugs, routes } from '@replay/slugs-generator';

type Taximony = {
    code: Codes | null;
    type: PageType;
    slug: Slugs;
};

type PageTypeByCode = {
    [key in Codes]: PageType;
};

const pageTypeByCode: PageTypeByCode = {
    [Codes.CATEGORY_ACT]: PAGE_TYPE_ACT_WITH_EBU_BOX,
    [Codes.CATEGORY_CIN]: PAGE_TYPE_CATEGORIES,
    [Codes.CATEGORY_SER]: PAGE_TYPE_CATEGORIES,
    [Codes.CATEGORY_CPO]: PAGE_TYPE_CATEGORIES,
    [Codes.CATEGORY_SCI]: PAGE_TYPE_CATEGORIES,
    [Codes.CATEGORY_DEC]: PAGE_TYPE_CATEGORIES,
    [Codes.CATEGORY_HIS]: PAGE_TYPE_CATEGORIES,

    [Codes.SUBCATEGORY_AJO]: PAGE_TYPE_SUBCATEGORIES,
    [Codes.SUBCATEGORY_ENQ]: PAGE_TYPE_SUBCATEGORIES,
    [Codes.SUBCATEGORY_KUL]: PAGE_TYPE_SUBCATEGORIES,
    [Codes.SUBCATEGORY_DCY]: PAGE_TYPE_SUBCATEGORIES,
    [Codes.SUBCATEGORY_JUN]: PAGE_TYPE_SUBCATEGORIES,
    [Codes.SUBCATEGORY_AUV]: PAGE_TYPE_SUBCATEGORIES,
    [Codes.SUBCATEGORY_FLM]: PAGE_TYPE_SUBCATEGORIES,
    [Codes.SUBCATEGORY_CMG]: PAGE_TYPE_SUBCATEGORIES,
    [Codes.SUBCATEGORY_CMU]: PAGE_TYPE_SUBCATEGORIES,
    [Codes.SUBCATEGORY_MCL]: PAGE_TYPE_SUBCATEGORIES,
    [Codes.SUBCATEGORY_ACC]: PAGE_TYPE_SUBCATEGORIES,
    [Codes.SUBCATEGORY_SES]: PAGE_TYPE_SUBCATEGORIES,
    [Codes.SUBCATEGORY_FIC]: PAGE_TYPE_SUBCATEGORIES,
    [Codes.SUBCATEGORY_WEB]: PAGE_TYPE_SUBCATEGORIES,
    [Codes.SUBCATEGORY_CHU]: PAGE_TYPE_SUBCATEGORIES,
    [Codes.SUBCATEGORY_ART]: PAGE_TYPE_SUBCATEGORIES,
    [Codes.SUBCATEGORY_POP]: PAGE_TYPE_SUBCATEGORIES,
    [Codes.SUBCATEGORY_IDE]: PAGE_TYPE_SUBCATEGORIES,
    [Codes.SUBCATEGORY_MUA]: PAGE_TYPE_SUBCATEGORIES,
    [Codes.SUBCATEGORY_MUE]: PAGE_TYPE_SUBCATEGORIES,
    [Codes.SUBCATEGORY_HIP]: PAGE_TYPE_SUBCATEGORIES,
    [Codes.SUBCATEGORY_MET]: PAGE_TYPE_SUBCATEGORIES,
    [Codes.SUBCATEGORY_CLA]: PAGE_TYPE_SUBCATEGORIES,
    [Codes.SUBCATEGORY_OPE]: PAGE_TYPE_SUBCATEGORIES,
    [Codes.SUBCATEGORY_JAZ]: PAGE_TYPE_SUBCATEGORIES,
    [Codes.SUBCATEGORY_MUD]: PAGE_TYPE_SUBCATEGORIES,
    [Codes.SUBCATEGORY_ADS]: PAGE_TYPE_SUBCATEGORIES,
    [Codes.SUBCATEGORY_BAR]: PAGE_TYPE_SUBCATEGORIES,
    [Codes.SUBCATEGORY_SAN]: PAGE_TYPE_SUBCATEGORIES,
    [Codes.SUBCATEGORY_ENN]: PAGE_TYPE_SUBCATEGORIES,
    [Codes.SUBCATEGORY_TEC]: PAGE_TYPE_SUBCATEGORIES,
    [Codes.SUBCATEGORY_ENB]: PAGE_TYPE_SUBCATEGORIES,
    [Codes.SUBCATEGORY_NEA]: PAGE_TYPE_SUBCATEGORIES,
    [Codes.SUBCATEGORY_EVA]: PAGE_TYPE_SUBCATEGORIES,
    [Codes.SUBCATEGORY_ATA]: PAGE_TYPE_SUBCATEGORIES,
    [Codes.SUBCATEGORY_VIA]: PAGE_TYPE_SUBCATEGORIES,
    [Codes.SUBCATEGORY_XXE]: PAGE_TYPE_SUBCATEGORIES,
    [Codes.SUBCATEGORY_CIV]: PAGE_TYPE_SUBCATEGORIES,
    [Codes.SUBCATEGORY_LGP]: PAGE_TYPE_SUBCATEGORIES,

    [Codes.DOR]: PAGE_TYPE_GENRES,
    [Codes.EMI]: PAGE_TYPE_GENRES,
    [Codes.HES]: PAGE_TYPE_GENRES,
    [Codes.SER_NORTH]: PAGE_TYPE_GENRES,
    [Codes.PRODNUM]: PAGE_TYPE_GENRES,
    [Codes.ARTE_CONCERT]: PAGE_TYPE_GENRES,
    [Codes.COLLECTION]: PAGE_TYPE_GENRES,

    [Codes.AVN]: PAGE_TYPE_UNKNOWN,
    [Codes.COLLECTORS]: PAGE_TYPE_UNKNOWN,
    [Codes.EDITORIAL_LAST_CHANCE]: PAGE_TYPE_UNKNOWN,
    [Codes.FESTIVAL_CANNES]: PAGE_TYPE_UNKNOWN,
    [Codes.LIVE]: PAGE_TYPE_UNKNOWN,
    [Codes.MAGAZINES]: PAGE_TYPE_UNKNOWN,

    [Codes.MOST_RECENT]: PAGE_TYPE_LISTS,
    [Codes.MOST_VIEWED]: PAGE_TYPE_LISTS,
    [Codes.LAST_CHANCE]: PAGE_TYPE_LISTS,
    [Codes.ACCESSIBLE_PROGRAMS]: PAGE_TYPE_LISTS,
    [Codes.AUDIO_DESCRIPTION]: PAGE_TYPE_LISTS,
    [Codes.CLOSED_CAPTIONING]: PAGE_TYPE_LISTS,
    [Codes.CLUB_HOME]: PAGE_TYPE_LISTS,
    [Codes.CONCERT_GUIDE]: PAGE_TYPE_LISTS,
    [Codes.TV_GUIDE]: PAGE_TYPE_LISTS,
    [Codes.SEARCH_HOME]: PAGE_TYPE_LISTS,
    [Codes.MY_ADVANTAGE]: PAGE_TYPE_LISTS,
};

export const getCodeAndTypeFromLocalizedSlug = (locale: Locale, slug: string): Taximony => {
    const remoteType = routes[locale].find((ct) => ct.slug === slug);

    if (remoteType) {
        const pageType = pageTypeByCode[remoteType.code];
        if (pageTypeByCode[remoteType.code] === PAGE_TYPE_UNKNOWN) {
            // todo : notify new-relic
        }
        return { ...remoteType, type: pageType };
    }

    // todo : notify new-relic

    return {
        code: null,
        type: PAGE_TYPE_UNKNOWN,
        slug: slug as Slugs,
    };
};
