import path from 'ramda/src/path';
import { type Teaser } from '@replay/types/Teaser';

export const getTrailerConfig = (program: Teaser | null | undefined) => {
    return path<string>(['trailer', 'config'], program);
};

export const getPlayerConfig = (program: Teaser | null | undefined) => {
    return path<string>(['player', 'config'], program);
};

export const getPlayerPlaylist = (program: Teaser | null | undefined) => {
    return path<string>(['player', 'playlist'], program);
};
