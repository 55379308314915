import { ProgramPage as ProgramPageDs } from '@artegeie/design-system/replay';
import { type ReactElement, useRef } from 'react';

import { type VideoKind } from '@artegeie/design-system/type';
import { useRouter } from 'next/router';
import { type Translate } from '@replay/i18n/types/translations';
import { AutoPlayAction, useAutoPlayChange } from '../../shared/useAutoPlayChange';
import { type Locale } from '@replay/i18n/types/locale';
import { getAlternativeLanguages, getMeta, getPage, type Page } from '@replay/types/Page';
import { type Teaser } from '@replay/types/Teaser';
import { getCode, getData, type Zone } from '@replay/types/Zone';
import { Player } from '../player/Player';
import { handleExternalInterface } from './createPlayerEvent';
import { getPlayerConfig, getTrailerConfig } from './getPlayerConfig';
import { generatePreviewData } from './getPreviewData';
import { ProgramMetas } from './ProgramMetas';
import { Error } from '../../shared/Error';
import { type Domain } from '@replay/types/MyGetServerSidePropsResult';
import { ok } from '@replay/types/Result';
import { type ApiPlayerConfigData, type ApiPlayerPlaylistItems } from '../../shared/api-player/commons';
import { getServerSideTracking } from '@replay/tracking/userStore';

export const getProgramFromZones = (zones: Zone[]): Teaser | undefined => {
    const predicate = (zone: Zone) => getCode(zone).includes('program_content');

    const programZone = zones.find(predicate);
    const data = programZone && getData(programZone);

    return data && data[0];
};

const toDsVideoKind = (videoKind: VideoKind) => {
    switch (videoKind) {
        case 'trailer':
            return 'Trailer';

        default:
            return 'Program';
    }
};

const createProgramPageError = (
    type: GlobalError['ProgramPage']['err']['type'],
    identifiers: string[],
): GlobalError['ProgramPage'] => ({
    type: 'ProgramPage',
    err: {
        type,
        stack: identifiers.join(', '),
    },
});

export type ProgramPageProps = {
    data: Page | null | undefined;
    videoKind: VideoKind;
    mamiBaseUrl: string;
    translate: Translate;
    locale: Locale;
    tcStartFrom: number | null;
    domain: Domain;
    apiPlayerConfig: ApiPlayerConfigData | null;
    apiPlayerPlaylist: ApiPlayerPlaylistItems | null;
};

const ProgramPage = ({
    data,
    mamiBaseUrl,
    translate,
    locale,
    tcStartFrom,
    videoKind,
    domain,
    apiPlayerConfig,
    apiPlayerPlaylist,
}: ProgramPageProps): ReactElement => {
    const program = data ? getProgramFromZones(data.zones) : null;
    const { autoPlay, onAutoPlayChange } = useAutoPlayChange();
    const { push, query } = useRouter();

    const identifiers = Array.isArray(query.identifiers) ? query.identifiers : [];

    const trailerButtonLabel =
        videoKind === 'trailer'
            ? translate('replay_internal.formaters.program.watch_full_video')
            : translate('program.trailer_button');

    const baseConfig = getPlayerConfig(program);
    const trailerConfig = getTrailerConfig(program);

    const config = videoKind === 'trailer' && trailerConfig ? trailerConfig : baseConfig;

    const setPositionRef: React.MutableRefObject<(url: string, position: number) => void> = useRef((url) => {
        push(url);
    });

    const onPrevNext = (url: string) => push(url);

    const onTrailerButtonClickHandler = () => {
        const [programId, programName] = identifiers;
        const query = videoKind === 'trailer' ? {} : { trailer: true };
        const pathname = `/${locale}/videos/${programId}/${programName}`;
        push({ pathname, query });
    };
    const onTrailerButtonClick = trailerConfig ? onTrailerButtonClickHandler : undefined;

    const handlePlayerEvent = handleExternalInterface({
        page: data,
        onPrevNext,
        setPositionRef,
        onTrailerButtonClickHandler,
    });

    const previewData = generatePreviewData(program);

    if (!program) {
        return <Error error={createProgramPageError('NoProgramFound', identifiers)} />;
    }

    if (!data) {
        return <Error error={createProgramPageError('NoData', identifiers)} />;
    }
    const meta = getMeta(data);
    const playerElement = (
        <Player
            key={`arte-vp${tcStartFrom}${videoKind}`}
            locale={locale}
            config={config || ''}
            videoKind={videoKind}
            getExternalInterface={handlePlayerEvent}
            autoPlayNext={autoPlay.next}
            translate={translate}
            previewData={previewData}
            fromDomain="other"
            tcStartFrom={tcStartFrom}
            apiPlayerConfig={apiPlayerConfig || undefined}
            apiPlayerPlaylist={apiPlayerPlaylist || undefined}
            hasIllico={false}
            playlistItems={[]}
            serverSideTracking={getServerSideTracking({
                locale: locale,
                domain: 'replay',
                type: 'emac',
                props: {
                    page: ok(data),
                },
            })}
        />
    );

    const geoblockingMode = apiPlayerConfig?.attributes?.restriction?.geoblocking?.restrictedArea
        ? 'Unavailable'
        : 'Available';

    return (
        <>
            <ProgramMetas
                alternativeLanguages={getAlternativeLanguages(data)}
                description={meta.description}
                language={data.language}
                mamiBaseUrl={mamiBaseUrl}
                page={getPage(data)}
                parent={data.parent}
                seo={data.seo || { title: meta.title }}
                title={data.seo ? data.seo.title : meta.title}
                url={data.url}
                zones={data.zones}
                meta={getMeta(data)}
            />

            <ProgramPageDs
                videoKind={toDsVideoKind(videoKind)}
                trailerButtonLabel={trailerButtonLabel}
                onTrailerButtonClick={onTrailerButtonClick}
                page={data}
                playedProgramId={program.id}
                player={playerElement}
                isPlayNextActivated={autoPlay.next}
                onPlayNextChange={onAutoPlayChange(AutoPlayAction.NEXT)}
                onChapterClick={(url: string, position: number) => {
                    setPositionRef.current(url, position);
                }}
                domain={domain}
                geoblockingMode={geoblockingMode}
                key={`ads-programPage${videoKind}`}
            />
        </>
    );
};

export default ProgramPage;
