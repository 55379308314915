import { RcPage } from '@artegeie/design-system/replay';
import { type ReactElement } from 'react';
import { getAlternativeLanguages, getMeta, getPage, type Page } from '@replay/types/Page';
import { CollectionMetas } from './CollectionMetas';
import { type Domain } from '@replay/types/MyGetServerSidePropsResult';

type Props = {
    data: Page;
    mamiBaseUrl: string;
    forceTrailer?: boolean;
    domain: Domain;
};

const CollectionPage = ({ data, mamiBaseUrl, forceTrailer, domain }: Props): ReactElement => {
    const alternativeLanguages = getAlternativeLanguages(data);
    const meta = getMeta(data);
    const page = getPage(data);

    return (
        <>
            <CollectionMetas
                title={meta.title}
                mamiBaseUrl={mamiBaseUrl}
                description={meta.description}
                url={data.url}
                language={data.language}
                page={page}
                zones={data.zones}
                alternativeLanguages={alternativeLanguages}
                parent={data.parent}
                meta={meta}
            />
            {data && <RcPage page={data} forceTrailer={forceTrailer} domain={domain} />}
        </>
    );
};

export default CollectionPage;
