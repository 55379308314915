import { type PreviewData, type Sticker } from '@artegeie/arte-vp';
import { type Teaser } from '@replay/types/Teaser';

const isValidSticker = (sticker: { code: string; label: string }): sticker is Sticker => {
    return sticker.code === 'LIVE_EVENT' || sticker.code === 'CLIP' || sticker.code === 'TRAILER';
};

export const generatePreviewData = (program: Teaser | null | undefined): PreviewData => {
    if (program) {
        const closestImage = program.mainImage?.url.replace('__SIZE__', '940x530');
        const stickers = program.stickers ? program.stickers.filter(isValidSticker) : [];
        return {
            title: program.title || '',
            subtitle: program.subtitle || '',
            image: closestImage || '',
            stickers: stickers,
        };
    }
    return {
        title: '',
        subtitle: '',
        image: '',
        stickers: [],
    };
};
